import { SearchIcon, ViewListIcon } from '@heroicons/react/solid';
import type { TFunction } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const features = (t: TFunction) => [
  {
    name: t('Søkeverktøy'),
    description: t(
      'Søkeverktøyet knytter forventede og støttende funn i klinikken til diagnoser. 127 funn er kategorisert i munnhulen, hode- og halsregionen og generelt. For hver hovedkategori er der en rekke underkategorier. Du kan se gjennom de ulike kategoriene eller bruke søkefeltet for å finne funn.',
    ),
    icon: SearchIcon,
    href: '/search',
    cta: t('Søkeverktøy'),
  },
  {
    name: t('Diagnoseoversikt'),
    description: t(
      'I diagnoseoversikten er alle diagnosene i databasen synlige. Over 200 diagnoser er inkludert, nye legges til kontinuerlig. Diagnosens navn følger navnet på diagnosen i den europeiske Orphanetdatabasen. I noen tilfeller er flere undertyper beskrevet under ett diagnosenavn. Ved å velge en diagnose kommer forventede og støttende funn ved diagnosen opp, deretter en generell beskrivelse og beskrivelse av munnhulefunn. Eventuelle rettigheter til økonomisk stønad ved tannbehandling beskrives i et eget punkt. Brukeren kan søke etter diagnosen synonymer, gentilknytning eller ORPHAkode.',
    ),
    icon: ViewListIcon,
    href: '/directory',
    cta: t('Diagnoseoversikt'),
  },
];

export default function Features() {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-congress-blue-400">
            <Trans t={t}>En støtte i diagnostikken</Trans>
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            <Trans t={t}>Søk på funn eller diagnose</Trans>
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            <Trans t={t}>
            Søkeverktøyet er utviklet for å kunne søke på kliniske funn uten at man kjenner til noen underliggende diagnose. Vi streber etter å inkludere de fleste sjeldne diagnoser med registrerte munnhulefunn.
            </Trans>
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            {features(t).map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                  <feature.icon
                    className="h-5 w-5 flex-none text-congress-blue-400"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <NavLink
                      to={feature.href}
                      className="text-sm font-semibold leading-6 text-congress-blue-400"
                    >
                      {feature.cta}
                      &nbsp;
                      <span aria-hidden="true">→</span>
                    </NavLink>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
