// import { BeakerIcon } from '@heroicons/react/outline';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import LanguageSelection from '../../components/LanguageSelection';
import { pages } from '../../navigation';

export default function Sidebar() {
  const { t, i18n } = useTranslation();

  return (
    <div className="hidden xl:fixed xl:inset-y-0 xl:flex xl:w-64 xl:flex-col">
      <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-1">
        <div className="flex flex-shrink-0 items-center px-3">
          <NavLink
            to="/"
            className="flex h-full w-full flex-shrink-0 items-center"
          >
            {/* <BeakerIcon className="h-8 w-8 text-congress-blue-500" /> */}
            <img
              alt="Logomark"
              src={`/assets/${i18n.language}/Logotype.png`}
              className="h-16 w-full object-contain"
            />
          </NavLink>
        </div>
        <div className="mt-5 flex flex-grow flex-col">
          <nav className="flex flex-col space-y-1 px-2 pb-4">
            {pages(t).map(({ title, link }) => (
              <NavLink
                end
                key={title}
                to={link}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? 'bg-gray-100 text-congress-blue-500'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-congress-blue-500'
                  } 'group font-medium' flex items-center rounded-md py-2 px-2 text-sm`
                }
              >
                {title}
              </NavLink>
            ))}
          </nav>
          <LanguageSelection />
        </div>
        <div className="flex flex-shrink-0 p-4">
          <div className="mt-8 space-y-3 xl:order-1 xl:mt-0">
            <p className="text-sm text-gray-400">
              <Trans t={t}>Publisert av</Trans>
            </p>
            <div className="mt-2 h-12 w-full">
              <span className="sr-only">
                <Trans t={t}>
                  NKSD - Nasjonal kompetansetjeneste for sjeldne diagnoser
                </Trans>
              </span>
              <img
                alt={t(
                  'NKSD - Nasjonal kompetansetjeneste for sjeldne diagnoser',
                )}
                src={`/assets/${i18n.language}/nksd_logo.png`}
                className="h-full w-full object-contain"
              />
            </div>
            {/* remove hidden */}
            {/* <div className="mt-2 h-12 w-full">
              <span className="sr-only">Description for second logo</span>
              <img
                alt="Description for second logo"
                src={OtherLogo}
                className="h-full w-full object-contain"
              />
            </div> */}
            <p className=" mt-4 text-sm text-gray-400">
              &copy; {new Date().getFullYear()} Papperslöst AB
              <br />
              <Trans t={t}>All rights reserved.</Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
