import type { MouseEvent } from 'react';
import { useCallback, useState } from 'react';

import Carousel from '../../components/Carousel';
import useModalState from '../../hooks/useModalState';
import Modal from '../Modal';

interface Image {
  link: string;
  description: string;
}

interface Props {
  images: Image[];
  name: string;
}

export default function Images({ name, images }: Props) {
  const [currentImgIdx, setCurrentImgIdx] = useState(0);
  const { show, openModal, closeModal } = useModalState();

  const updateImage = useCallback(
    ({ currentTarget: { value } }: MouseEvent<HTMLButtonElement>) => {
      setCurrentImgIdx(parseInt(value));
    },
    [],
  );

  return (
    <>
      <div className="relative ml-2 aspect-[3/2] h-16 rounded-md  lg:h-24">
        <img
          className="h-full w-full object-scale-down hover:opacity-75"
          src={images[currentImgIdx].link  || ''}
          alt={images[currentImgIdx].description}
          onClick={openModal}
        />
        {images.length > 1 && (
          <div className="absolute bottom-2 right-0 left-0 flex items-center justify-center gap-1">
            {images.map((image, index) => (
              <button
                key={image.link}
                className={`inline-block h-2 w-2 rounded-full opacity-75 hover:opacity-100 ${
                  index === currentImgIdx ? 'bg-azure-500' : 'bg-gray-300'
                }`}
                value={index}
                onClick={updateImage}
              >
                <span className="sr-only">{index}</span>
              </button>
            ))}
          </div>
        )}
      </div>

      <Modal title={name} show={show} closeModal={closeModal}>
        <div className="grid w-full grid-cols-1">
          <div className="aspect-h-3 aspect-w-2 h-[75vh]">
            {/*
            // Note the equivalent of startIndex is not implemented using DaisyUI
            <Carousel images={images} startIndex={currentImgIdx} />
            */}
            <div className="carousel w-full h-full">
              {images.map((image, index) => {
                const shouldShowButtons = images.length > 1;

                return (
                  <div id={`slide${index +1}`} className="carousel-item relative w-full">
                    <div className="flex flex-col items-center w-full h-[60vh]">
                      <img 
                        src={image.link || ''}
                        alt={image.description}
                        className="mx-auto max-w-full max-h-full object-contain"/>
                      {image.description && <p className="text-center bg-gray-100 px-4 py-4 text-sm text-gray-500 mx-20">{image.description}</p>}
                    </div>
                    {shouldShowButtons && (
                      <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href={`#slide${index === 0 ? images.length : index}`} className="btn btn-circle">❮</a> 
                        <a href={`#slide${index + 2 > images.length ? 1 : index + 2}`} className="btn btn-circle">❯</a>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            {/*
            Possible extension, not implemented now (should use map and set the bg-color dynamically)
            <div className="flex justify-center w-full gap-2">
              <a href="#slide1" className="btn btn-xs bg-gray-300">1</a> 
              <a href="#slide2" className="btn btn-xs">2</a> 
              <a href="#slide3" className="btn btn-xs">3</a> 
              <a href="#slide4" className="btn btn-xs">4</a>
              <a href="#slide5" className="btn btn-xs">5</a>
              <a href="#slide6" className="btn btn-xs">6</a>
              <a href="#slide7" className="btn btn-xs">7</a>
            </div>
            */}
          </div>
        </div>
      </Modal>
    </>
  );
}
