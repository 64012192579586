import type { TFunction } from 'i18next';

export const pages = (t: TFunction): PagesLink[] => [
  {
    title: t('Hjem'),
    link: '/',
  },
  {
    title: t('Søkeverktøy'),
    link: '/search',
  },
  {
    title: t('Diagnoseoversikt'),
    link: '/directory',
  },
  {
    title: t('Ofte stilte spørsmål'),
    link: '/faq',
  },
];
