import type { PropsWithChildren } from 'react';

import Footer from './Footer';
import Header from './Header';

export default function HomeLayout({ children }: PropsWithChildren) {
  return (
    <div className="bg-white">
      <Header />
      <main className="isolate">{children}</main>
      <Footer />
    </div>
  );
}
