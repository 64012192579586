import { useCallback, useState } from 'react';

export default function useModalState(initialState = false) {
  const [show, setShow] = useState(initialState);

  const openModal = useCallback(() => setShow(true), []);
  const closeModal = useCallback(() => setShow(false), []);
  const toggleModal = useCallback(() => setShow((prev) => !prev), []);

  return { show, openModal, closeModal, toggleModal };
}
