import { ChevronDownIcon } from '@heroicons/react/outline';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Diagnoses from '../components/Diagnoses';
import SignsWithSearch from '../components/SignsWithSearch';
import { SidePanelContext } from '../contexts/SidePanelStateContext';
import SelecteFindingsMenu from './ActiveFindingsMenu';
import SidePanel from './SidePanel';

export default function Home() {
  const [isOpen, setIsOpen] = useContext(SidePanelContext);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const findings = useMemo(() => searchParams.getAll('signs'), [searchParams]);

  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  useEffect(() => {
    if (searchParams.get('signs') === null) {
      setIsOpen(true);
    }
  }, [searchParams, setIsOpen]);

  return (
    <>
      <Diagnoses openMenu={openMenu} />
      <SidePanel
        title={t('Kliniske funn')}
        openPanel={setIsOpen}
        isOpen={isOpen}
      >
        <SelecteFindingsMenu
          disabled={findings.length === 0}
          resetSearch={openMenu}
        >
          <span className="text-medium text-left inline-flex select-none items-center justify-center space-x-2  py-2 text-gray-600">
          {findings.length === 0 ? (
              <Trans t={t}>
                Hvilke kliniske funn har din pasient? Velg ett eller flere kliniske funn der minst ett er i munnhulen og få opp forslag til hvilken sjelden diagnose disse kan tilhøre.
              </Trans>
          ) : (
            <>
              <ChevronDownIcon className="h-5 w-5 mr-2 group-hover:text-azure-500 group-focus:text-azure-500" />
              {t('findings', { count: findings.length })}
            </>
          )}
          </span>
        </SelecteFindingsMenu>
        <SignsWithSearch />
      </SidePanel>
    </>
  );
}
