import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Button from '../Button';

export default function Cta() {
  const { t } = useTranslation();
  return (
    <div className="relative -z-10 mt-32 p-6 lg:p-8">
      <div
        className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
        aria-hidden="true"
      >
        <div
          className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#B9727D] to-[#379CFF] opacity-25"
          style={{
            clipPath:
              'polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <Trans t={t}>Bruk oss som støtte</Trans>
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
          <Trans t={t}>
          En sjelden diagnose kan bety uvanlige funn, et annet risikobilde eller en annen behandlingstilnærming. Ofte alle sammen. Unike tenner og Nasjonalt kompetansesenter for oral helse ved sjeldne diagnoser hjelper deg å ivareta pasienten.
          </Trans>
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button
            as={NavLink}
            to="/search"
            className="rounded-md bg-congress-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-congress-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-congress-blue-600"
          >
            <Trans t={t}>Start søk</Trans>
          </Button>
          <NavLink
            to="/directory"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            <Trans t={t}>Diagnoseoversikt</Trans> &nbsp;
            <span aria-hidden="true">→</span>
          </NavLink>
        </div>
      </div>
      <div
        className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
        aria-hidden="true"
      >
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#B9727D] to-[#379CFF] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  );
}
