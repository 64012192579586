import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

export default function MarkdownToHtml({ children }: { children: string }) {
  return (
    <div className="prose prose-sm prose-congress-blue rounded-md border border-gray-200 p-4 prose-headings:text-base">
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[gfm]}>
        {children}
      </ReactMarkdown>
    </div>
  );
}
