import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export default function FourOFour() {
  const { t } = useTranslation();

  return (
    <div className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
      <div className="py-16">
        <div className="text-center">
          <p className="text-sm font-semibold uppercase tracking-wide text-congress-blue-600">
            <Trans t={t}>404 feil</Trans>
          </p>
          <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-congress-blue-900 sm:text-5xl">
            <Trans t={t}>Side ikke funnet.</Trans>
          </h1>
          <p className="mt-2 text-base text-gray-500">
            <Trans t={t}>Beklager, vi fant ikke siden du leter etter.</Trans>
          </p>
          <div className="mt-6">
            <NavLink
              to="/"
              className="text-base font-medium text-congress-blue-600 hover:text-congress-blue-500"
            >
              <Trans t={t}>Gå tilbake til startsiden</Trans>
              <span aria-hidden="true"> &rarr;</span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
