import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../layouts/Button';

type Props = {
  onClick: MouseEventHandler;
};

export default function ResetButton({ onClick }: Props) {
  const { t } = useTranslation();
  const [_searchParams, setSearchParams] = useSearchParams();

  const onClickButton: MouseEventHandler = useCallback(
    (event) => {
      setSearchParams({});
      if (onClick) {
        onClick(event);
      }
    },
    [onClick, setSearchParams],
  );

  return (
    <Button type="reset" onClick={onClickButton} variant="primary">
      <PlusIcon className="h-5 w-5 md:mr-2 md:-ml-1" aria-hidden="true" />
      <span className="sr-only md:not-sr-only">
        <Trans t={t}>Nytt diagnosesøk</Trans>
      </span>
    </Button>
  );
}
