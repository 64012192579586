import type { PropsWithChildren, Reducer } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

enum Actions {
  FECTH_DATA_REQUEST = 'FECTH_DATA_REQUEST',
  FECTH_DATA_SUCCESS = 'FECTH_DATA_SUCCESS',
  FECTH_DATA_FAILURE = 'FECTH_DATA_FAILURE',
}

type Action =
  | {
      type: Actions.FECTH_DATA_REQUEST;
    }
  | {
      type: Actions.FECTH_DATA_SUCCESS;
      payload: {
        categories: Category[];
        signs: Sign[];
      };
    }
  | {
      type: Actions.FECTH_DATA_FAILURE;
      payload: {
        error: string;
      };
    };

type State = SignsContextValue & {
  fetching: boolean;
  error: string | null;
};

const reducer: Reducer<State, Action> = (state: State, action: Action) => {
  switch (action.type) {
    case Actions.FECTH_DATA_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case Actions.FECTH_DATA_SUCCESS: {
      const categories = new Map();
      const signsByCategory = new Map();
      for (const category of action.payload.categories) {
        categories.set(category.name, category.subCategories);
        signsByCategory.set(
          category.name,
          new Map(
            category.subCategories.map((subcategory) => [subcategory, []]),
          ),
        );
      }
      for (const sign of action.payload.signs) {
        const { category, subCategory } = sign;
        const subCategories = signsByCategory.get(category);
        if (subCategories && subCategories.has(subCategory)) {
          subCategories.get(subCategory)?.push(sign);
        }
      }
      return {
        ...state,
        fetching: false,
        error: null,
        categories,
        signs: action.payload.signs,
        signsByCategory,
      };
    }
    case Actions.FECTH_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

interface SignsProviderProps {}

type SignsContextValue = {
  categories: Map<string, string[]>;
  signs: Sign[];
  signsByCategory: Map<string, Map<string, Sign[]>>;
};

const SignsContext = createContext<SignsContextValue>({
  categories: new Map(),
  signs: [],
  signsByCategory: new Map(),
});

function SignsProvider({ children }: PropsWithChildren<SignsProviderProps>) {
  const [state, dispatch] = useReducer(reducer, {
    fetching: false,
    error: null,
    categories: new Map(),
    signs: [],
    signsByCategory: new Map(),
  });
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();

    const fetchSigns = async () => {
      dispatch({ type: Actions.FECTH_DATA_REQUEST });
      return Promise.all([
        fetch(`/data/${i18n.language}/signs.json`, {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        fetch(`/data/${i18n.language}/categories.json`, {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
      ])
        .then(([responseSigns, responseCategories]) => {
          if (!responseSigns.ok || !responseCategories.ok) {
            dispatch({
              type: Actions.FECTH_DATA_FAILURE,
              payload: { error: 'Failed to fetch data' },
            });
            navigate('/404');
          }
          return Promise.all([responseSigns.json(), responseCategories.json()]);
        })
        .then(([signs, categories]: [Sign[], Category[]]) => {
          if (signs && categories) {
            dispatch({
              type: Actions.FECTH_DATA_SUCCESS,
              payload: { signs, categories },
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: Actions.FECTH_DATA_FAILURE,
            payload: { error: error.message },
          });
          // navigate('/404');
        });
    };

    fetchSigns();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <SignsContext.Provider
      value={{
        signs: state.signs,
        categories: state.categories,
        signsByCategory: state.signsByCategory,
      }}
    >
      {children}
    </SignsContext.Provider>
  );
}

export { SignsContext, SignsProvider };
