import { useCallback } from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { languages } from '../languages';

export default withTranslation()(function LanguageSelection({ t, i18n }) {
  const changeLanguage = useCallback(
    (lng: string) => {
      i18n.changeLanguage(lng);
    },
    [i18n],
  );

  return (
    <div className="flex-1 py-2 px-4">
      <label
        htmlFor="language"
        className="block text-sm font-medium leading-6 text-gray-600 hover:bg-gray-50"
      >
        <Trans t={t}>Språk</Trans>
      </label>
      <select
        id="language"
        name="language"
        className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-congress-blue-500 focus:ring-2 focus:ring-congress-blue-600 sm:text-sm sm:leading-6"
        defaultValue={i18n.language}
        onChange={(e) => {
          changeLanguage(e.target.value);
          window.scrollTo(0, 0);
        }}
      >
        {languages.map((language) => (
          <option key={language.value} value={language.value}>
            {language.label}
          </option>
        ))}
      </select>
    </div>
  );
});
