import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Button from '../Button';

export default function HeroSection() {
  const { t } = useTranslation();

  return (
    <div className="relative isolate overflow-hidden bg-gradient-to-b from-congress-blue-100/20 pt-14">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#B9727D] to-[#379CFF] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
          <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
            <Trans t={t}>Se det sjeldne</Trans>
          </h1>
          <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
            <p className="text-lg leading-8 text-gray-600">
              <Trans t={t}>
                Unike tenner er et søkeverktøy og en diagnoseoversikt med fokus
                på sjeldne diagnoser med munnhulefunn. En sjelden diagnose kan
                bety uvanlige funn, et annet risikobilde eller en annen
                tilnærming.
              </Trans>
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Button
                as={NavLink}
                to="/search"
                className="rounded-md bg-congress-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-congress-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-congress-blue-500"
                variant="primary"
              >
                <Trans t={t}>Start søk</Trans>
              </Button>
              <NavLink
                to="/directory"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                <Trans t={t}>Diagnoseoversikt</Trans>&nbsp;
                <span aria-hidden="true">→</span>
              </NavLink>
            </div>
          </div>
          <img
            src="https://images.unsplash.com/photo-1600170311833-c2cf5280ce49?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1280&q=80"
            alt=""
            className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
          />
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
    </div>
  );
}
