import About from './About';
import Cta from './Cta';
import FAQ from './Faq';
import Features from './Features';
import HeroSection from './HeroSection';
import HomeLayout from './HomeLayout';

export default function Home() {
  return (
    <HomeLayout>
      <HeroSection />
      <Features />
      <FAQ />
      <Cta />
      <About />
    </HomeLayout>
  );
}
