import { Menu, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import type {
  ChangeEventHandler,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import { Fragment, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

interface SelecteFindingsMenuProps {
  disabled?: boolean;
  resetSearch: MouseEventHandler;
}

export default function SelecteFindingsMenu({
  children,
  disabled = false,
  resetSearch,
}: PropsWithChildren<SelecteFindingsMenuProps>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const findings = useMemo(() => searchParams.getAll('signs'), [searchParams]);

  const removeSign: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ currentTarget: { value } }) => {
      const signs = searchParams.getAll('signs');
      setSearchParams({ signs: signs?.filter((item) => item !== value) });
      if (signs.length === 1) {
        resetSearch();
      }
    },
    [resetSearch, searchParams, setSearchParams],
  );

  return (
    <Menu as="div" className="relative max-w-max">
      <Menu.Button
        disabled={findings.length === 0 || disabled}
        className="group inline-flex items-center gap-x-1 text-sm leading-5 text-gray-500 hover:text-congress-blue-500"
      >
        {children}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 flex w-screen max-w-max -translate-x-4 px-4">
          <div className="w-screen max-w-max flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="grid grid-cols-1 divide-x divide-gray-900/5 bg-gray-50">
              {findings.map((item) => (
                <Menu.Item
                  key={item}
                  as="button"
                  className="group flex items-center justify-start gap-x-2.5 p-3 text-gray-700 hover:bg-gray-100"
                  onClick={removeSign}
                  value={item}
                >
                  <XIcon
                    className="h-5 w-5 flex-none text-gray-400 group-hover:text-pink-flare-900"
                    aria-hidden="true"
                  />
                  {item}
                </Menu.Item>
              ))}
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
