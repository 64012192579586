import { Trans, useTranslation } from 'react-i18next';

import Anchor from '../Anchor';

export default function About() {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <p className="text-base font-semibold leading-7 text-congress-blue-600">
            <Trans t={t}></Trans>
          </p>
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            <Trans t={t}>Om Unike tenner</Trans>
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            <Trans t={t}>
              Nasjonalt kompetansesenter for oral helse ved sjeldne diagnoser
              (TAKO-senteret) ved Lovisenberg diakonale sykehus i Oslo står bak
              søkeverktøyet og diagnoseoversikten.
            </Trans>
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            <Trans t={t}>
              Dersom du har innspill eller forslag ber vi deg om å sende disse til
            </Trans>
            &nbsp;<Anchor href="mailto:info@uniketenner.no">info@uniketenner.no</Anchor>
          </p>
        </div>
      </div>
    </div>
  );
}
