import type { PropsWithChildren } from 'react';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export class Diagnosis {
  geneAssociation?: string;
  generalFindings?: string;
  //generalFindingsImages: string[];
  name: string;
  nameAndCodeOrphanet?: string;
  oralFindings?: string;
  //oralFindingsImages: string[];
  prevalence?: string;
  rarity?: string;
  references?: string;
  rights?: string;
  synonymsEnglish?: string[];

  // for the matching
  expected: Set<string>;
  supportives: Set<string>;
  matchExpected = 0;
  matchSupportives = 0;
  relevance = false;

  constructor(matchRule: MatchRule) {
    this.name = matchRule.diagnosis;
    this.expected = new Set<string>();
    this.supportives = new Set<string>();
    this.addRule(matchRule);
    //this.generalFindingsImages = [];
    //this.oralFindingsImages = [];
  }

  addRule(rule: MatchRule) {
    switch (rule.matchRule) {
      case 'expected':
        this.expected.add(rule.signName);
        break;
      case 'supportive':
        this.supportives.add(rule.signName);
        break;
      default:
        throw new Error('Unknown match rule');
    }
  }

  addSupportive(supportive: string) {
    this.supportives.add(supportive);
  }

  addExpected(expected: string) {
    this.expected.add(expected);
  }

  get ratioExpected() {
    return this.matchExpected / this.expected.size;
  }

  set description(description: Disorder) {
    this.geneAssociation = description.geneAssociation;
    this.generalFindings = description.generalFindings;
    //this.generalFindingsImages = description.generalFindingsImages || [];
    this.nameAndCodeOrphanet = description.nameAndCodeOrphanet;
    this.oralFindings = description.oralFindings;
    //this.oralFindingsImages = description.oralFindingsImages || [];
    this.prevalence = description.prevalence;
    this.rarity = description.rarity;
    this.synonymsEnglish = description.synonymsEnglish
      ?.split(',')
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
    this.rights = description.rights;
    this.references = description.references;
  }

  toString() {
    return this.name;
  }

  matchSearch(query: string) {
    return (
      this.name.toLowerCase().includes(query.toLowerCase()) ||
      this.nameAndCodeOrphanet?.toLowerCase().includes(query.toLowerCase()) ||
      this.synonymsEnglish?.some((synonym) =>
        synonym.toLowerCase().includes(query.toLowerCase()),
      ) ||
      this.geneAssociation?.toLowerCase().includes(query.toLowerCase())
    );
  }

  static compare(a: Diagnosis, b: Diagnosis) {
    // first compare matching expected
    const match = b.matchExpected - a.matchExpected;
    if (match !== 0) {
      return match;
    }
    // then compare ration expected/total expected
    const rationExpected = b.ratioExpected - a.ratioExpected;
    if (rationExpected !== 0) {
      return rationExpected;
    }
    // finally compare matching supportives
    return b.matchSupportives - a.matchSupportives;
  }

  static compareName(a: Diagnosis, b: Diagnosis) {
    return a.name.localeCompare(b.name);
  }
}

interface DiagnosesProviderProps {}

type DiagnosesContextValue = {
  diagnoses: Diagnosis[];
};

const DiagnosesContext = createContext<DiagnosesContextValue>({
  diagnoses: [],
});

function DiagnosesProvider({
  children,
}: PropsWithChildren<DiagnosesProviderProps>) {
  const [diagnoses, setDiagnoses] = useState<Diagnosis[]>([]);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      Promise.all([
        fetch(`/data/${i18n.language}/diagnoses.json`, {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        fetch(`/data/${i18n.language}/matchRules.json`, {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
      ])
        .then(([diasgnosesResponse, rulesResponse]) => {
          if (!diasgnosesResponse.ok || !rulesResponse.ok) {
            navigate('/404');
          }
          return Promise.all([diasgnosesResponse.json(), rulesResponse.json()]);
        })
        .then(([diagnoses, matchRules]: [Disorder[], MatchRule[]]) => {
          const rulesMap = new Map<string, Diagnosis>();

          for (const rule of matchRules) {
            if (rulesMap.has(rule.diagnosis)) {
              rulesMap.get(rule.diagnosis)?.addRule(rule);
            } else {
              rulesMap.set(rule.diagnosis, new Diagnosis(rule));
            }
          }

          for (const disorder of diagnoses) {
            if (rulesMap.has(disorder.diagnosis)) {
              rulesMap.get(disorder.diagnosis).description = disorder;
            }
          }
          setDiagnoses(Array.from(rulesMap.values()));
        });
    };

    fetchData();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <DiagnosesContext.Provider value={{ diagnoses }}>
      {children}
    </DiagnosesContext.Provider>
  );
}

export { DiagnosesContext, DiagnosesProvider };
