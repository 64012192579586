import i18n from 'i18next';
import {
  default as LanguageDetector,
  default as detector,
} from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(detector)
  .use(ChainedBackend)
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    saveMissing: true,

    fallbackLng: 'no',

    supportedLngs: ['no', 'en'],

    nonExplicitSupportedLngs: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    load: 'currentOnly',

    backend: {
      backends: [
        LocalStorageBackend,
        HttpBackend,
        resourcesToBackend(
          (lng: any, ns: any) => import(`../public/locales/${lng}/${ns}.json`),
        ),
      ],
      backendOptions: [
        {
          expirationTime:
            process.env.REACT_APP_ENV === 'production'
              ? 7 * 24 * 60 * 60 * 1000 // 7 days
              : 0, // DO NOT cache in development
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  });

export default i18n;
