import type { Reducer } from 'react';
import { useEffect, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import List from '../layouts/List';
import map from '../utils/map';

enum Actions {
  END_VALIDATION = 'END_VALIDATION',
  START_VALIDATION = 'START_VALIDATION',
  SET_ERRORS = 'SET_ERRORS',
  FECTH_DATA_REQUEST = 'FECTH_DATA_REQUEST',
  FECTH_DATA_FAIL = 'FECTH_DATA_FAIL',
  FECTH_DATA_SUCCESS = 'FECTH_DATA_SUCCESS',
}

type Action =
  | {
      type: Actions.SET_ERRORS;
      payload: {
        file: string;
        errors: string[];
      };
    }
  | {
      type: Actions.START_VALIDATION | Actions.END_VALIDATION;
    }
  | {
      type: Actions.FECTH_DATA_REQUEST;
    }
  | {
      type: Actions.FECTH_DATA_FAIL;
      payload: {
        error: string;
      };
    }
  | {
      type: Actions.FECTH_DATA_SUCCESS;
      payload: {
        categories: Category[];
        diagnoses: Disorder[];
        matchRules: MatchRule[];
        signs: Sign[];
      };
    };

type State = {
  errors: Map<string, string[]>;
  isValidating: boolean;
  isFetching: boolean;
  fetchErrors: string[];
  categories: Category[];
  diagnoses: Disorder[];
  matchRules: MatchRule[];
  signs: Sign[];
};

const reducer: Reducer<State, Action> = (state: State, action: Action) => {
  switch (action.type) {
    case Actions.START_VALIDATION:
      return {
        ...state,
        isValidating: true,
      };

    case Actions.END_VALIDATION:
      return {
        ...state,
        isValidating: false,
      };

    case Actions.FECTH_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        fetchErrors: [],
      };

    case Actions.FECTH_DATA_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchErrors: [...state.fetchErrors, action.payload.error],
      };

    case Actions.FECTH_DATA_SUCCESS:
      return {
        ...state,
        // start validation as soon as fetch is completed
        isValidating: true,
        errors: new Map(),
        isFetching: false,
        ...action.payload,
      };

    case Actions.SET_ERRORS: {
      const { file, errors } = action.payload;
      const errorsMap = new Map(state.errors);

      errorsMap.set(file, [...(errorsMap.get(file) || []), ...errors]);

      return {
        ...state,
        errors: errorsMap,
      };
    }

    default:
      return state;
  }
};

export default function Validation() {
  const { t, i18n } = useTranslation();

  const [state, dispatch] = useReducer(reducer, {
    errors: new Map(),
    isFetching: true,
    isValidating: true,
    fetchErrors: [],
    categories: [],
    diagnoses: [],
    matchRules: [],
    signs: [],
  });

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      dispatch({ type: Actions.FECTH_DATA_REQUEST });
      await Promise.all([
        fetch(`/data/${i18n.language}/signs.json`, {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        fetch(`/data/${i18n.language}/categories.json`, {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        fetch(`/data/${i18n.language}/diagnoses.json`, {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        fetch(`/data/${i18n.language}/matchRules.json`, {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
      ])
        .then((responses) =>
          Promise.all(
            responses.map((response) => {
              if (!response.ok) {
                dispatch({
                  type: Actions.FECTH_DATA_FAIL,
                  payload: {
                    error: `${response.url}: HTTP error ${response.status}`,
                  },
                });
                return [];
              }
              return response.json();
            }),
          ),
        )
        .then((jsons) => {
          const [signs, categories, diagnoses, matchRules]: [
            Sign[],
            Category[],
            Disorder[],
            MatchRule[],
          ] = jsons as [Sign[], Category[], Disorder[], MatchRule[]];
          dispatch({
            type: Actions.FECTH_DATA_SUCCESS,
            payload: { categories, diagnoses, matchRules, signs },
          });
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            dispatch({
              type: Actions.FECTH_DATA_FAIL,
              payload: {
                error: `${error.message}`,
              },
            });
          }
        });
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [i18n.language]);

  useEffect(() => {
    if (state.isValidating && !state.isFetching) {
      // we assume categories and subcategories contain all possible values
      const categories = new Map(
        state.categories.map((category) => {
          const { name, subCategories } = category;

          if (name !== String(name).trim()) {
            dispatch({
              type: Actions.SET_ERRORS,
              payload: {
                file: 'categories.json',
                errors: [
                  `Category "${name}" has leading or trailing whitespace`,
                ],
              },
            });
          }

          if (/\s{2,}/.test(name)) {
            dispatch({
              type: Actions.SET_ERRORS,
              payload: {
                file: 'categories.json',
                errors: [`Category "${name}" has multiple whitespace`],
              },
            });
          }

          for (const subcategory of subCategories) {
            if (subcategory !== String(subcategory).trim()) {
              dispatch({
                type: Actions.SET_ERRORS,
                payload: {
                  file: 'categories.json',
                  errors: [
                    `Subcategory "${subcategory}" has leading or trailing whitespace`,
                  ],
                },
              });
            }

            if (/\s{2,}/.test(subcategory)) {
              dispatch({
                type: Actions.SET_ERRORS,
                payload: {
                  file: 'categories.json',
                  errors: [
                    `Subcategory "${subcategory}" has multiple whitespace`,
                  ],
                },
              });
            }
          }

          return [name, new Set(subCategories)];
        }),
      );

      const subCategories = new Set(
        state.categories.reduce((acc, category) => {
          for (const catgeroy of category.subCategories) {
            acc.add(catgeroy);
          }
          return acc;
        }, new Set<string>()),
      );

      const unusedCategories = new Set(categories.keys());
      const unusedSubcategories = new Set(subCategories.values());

      // valide that all signs are in exisitng categories and subcategories
      const duplicatedSigns = new Set<string>();
      state.signs.forEach((sign) => {
        const { name, category, subCategory } = sign;

        if (duplicatedSigns.has(`${category}/${subCategory}/${name}`)) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'signs.json',
              errors: [`Duplicated sign "${name}"`],
            },
          });
        } else {
          duplicatedSigns.add(`${category}/${subCategory}/${name}`);
        }

        if (name !== String(name).trim()) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'signs.json',
              errors: [`Sign "${name}" has leading or trailing whitespace`],
            },
          });
        }

        if (/\s{2,}/.test(name)) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'signs.json',
              errors: [`Sign "${name}" has multiple whitespace`],
            },
          });
        }

        if (!categories.has(category)) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'signs.json',
              errors: [
                `Sign "${name}" has a missing category "${category}" in categories.json`,
              ],
            },
          });
        } else {
          unusedCategories.delete(category);

          const subCategories = categories.get(category);

          if (subCategories && !subCategories.has(subCategory)) {
            dispatch({
              type: Actions.SET_ERRORS,
              payload: {
                file: 'signs.json',
                errors: [
                  `Sign "${name}" has a missing subcategory "${subCategory}" is not in the category "${category}" (in categories.json)`,
                ],
              },
            });
          } else {
            unusedSubcategories.delete(subCategory);
          }
        }

        if (!subCategories.has(subCategory)) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'signs.json',
              errors: [
                `Sign "${name}" has a missing subcategory "${subCategory}" in categories.json`,
              ],
            },
          });
        }
      });
      if (unusedCategories.size > 0 || unusedSubcategories.size > 0) {
        dispatch({
          type: Actions.SET_ERRORS,
          payload: {
            file: 'categories.json',
            errors: [
              ...map(
                unusedCategories.values(),
                (category) =>
                  `Category "${category}" is not used in signs.json`,
              ),
              ...map(
                unusedSubcategories.values(),
                (subcategory) =>
                  `Subcategory "${subcategory}" is not used in signs.json`,
              ),
            ],
          },
        });
      }

      const signs = new Set(state.signs.map((sign) => sign.name));
      const unusedSigns = new Set(signs.values());

      // set of diagnosis to look up for them faster than an array and check they are unique
      const diagnoses = new Set<string>();
      const unusedDiagnoses = new Set(state.diagnoses.map((d) => d.diagnosis));

      for (const diagnosis of state.diagnoses) {
        if (diagnoses.has(diagnosis.diagnosis)) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'diagnoses.json',
              errors: [`Duplicated diagnosis "${diagnosis.diagnosis}"`],
            },
          });
        } else {
          diagnoses.add(diagnosis.diagnosis);
        }

        if (diagnosis.diagnosis !== String(diagnosis.diagnosis).trim()) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'diagnoses.json',
              errors: [
                `Diagnosis "${diagnosis.diagnosis}" has leading or trailing whitespace`,
              ],
            },
          });
        }

        if (/\s{2,}/.test(diagnosis.diagnosis)) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'diagnoses.json',
              errors: [
                `Diagnosis "${diagnosis.diagnosis}" has multiple whitespace`,
              ],
            },
          });
        }
      }

      const duplicatedRules = new Map<string, number>();

      for (const rule of state.matchRules) {
        const { diagnosis, signName, matchRule } = rule;

        if (duplicatedRules.has(`${diagnosis}.${signName}`)) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'matchRules.json',
              errors: [
                `Match rule is duplicated for diagnosis "${diagnosis}" and sign "${signName}"`,
              ],
            },
          });
        }
        duplicatedRules.set(
          `${diagnosis}.${signName}`,
          duplicatedRules.get(`${diagnosis}.${signName}`) ?? 0 + 1,
        );

        if (matchRule !== 'expected' && matchRule !== 'supportive') {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'matchRules.json',
              errors: [
                `Match rule "${matchRule}" has wrong value for diagnosis "${diagnosis}" and sign "${signName}" (correct values: "expected" or "supportive")`,
              ],
            },
          });
        }

        if (!signs.has(signName)) {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'matchRules.json',
              errors: [
                `Sign "${signName}" is missing in signs.json (for ${diagnosis})`,
              ],
            },
          });
        } else {
          unusedSigns.delete(signName);
        }

        if (diagnoses.has(diagnosis)) {
          unusedDiagnoses.delete(diagnosis);
        } else {
          dispatch({
            type: Actions.SET_ERRORS,
            payload: {
              file: 'matchRules.json',
              errors: [
                `Diagnosis "${diagnosis}" is missing in diagnoses.json (for ${signName})`,
              ],
            },
          });
        }
      }

      if (unusedSigns.size > 0) {
        dispatch({
          type: Actions.SET_ERRORS,
          payload: {
            file: 'signs.json',
            errors: map(
              unusedSigns.values(),
              (unusedSign) =>
                `Sign "${unusedSign}" is not used (in matchRules.json)`,
            ),
          },
        });
      }

      if (unusedDiagnoses.size > 0) {
        dispatch({
          type: Actions.SET_ERRORS,
          payload: {
            file: 'diagnoses.json',
            errors: map(
              unusedDiagnoses.values(),
              (diagnosis) =>
                `Diagnosis "${diagnosis}" is not used (in matchRules.json)`,
            ),
          },
        });
      }

      dispatch({ type: Actions.END_VALIDATION });
      // const signNames = new Set(state.signs.map((sign) => sign.name));
    }
  }, [
    state.categories,
    state.diagnoses,
    state.isFetching,
    state.isValidating,
    state.matchRules,
    state.signs,
  ]);

  return (
    <div className="relative isolate overflow-hidden pt-14">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3x mx-auto">
          <div className="my-12 max-w-2xl">
            <h1 className="text-4xl font-extrabold tracking-tight text-congress-blue-700 sm:text-5xl">
              {state.isValidating ? 'Validating...' : 'Validation complete'}
            </h1>
            <h2
              className={`mt-1 text-gray-500 ${
                !state.isValidating &&
                state.fetchErrors.length === 0 &&
                state.errors.size === 0
                  ? 'block'
                  : 'hidden'
              }`}
            >
              No errors found 🎉
            </h2>
            <h2
              className={`mt-1 text-red-600 ${
                state.fetchErrors.length > 0 || state.errors.size > 0
                  ? 'block'
                  : 'hidden'
              }`}
            >
              Please check the errors below
            </h2>
          </div>
          <List>
            {state.fetchErrors.map((error) => (
              <List.Item key={error} className="py-3">
                {error}
              </List.Item>
            ))}
          </List>
          <dl data-testid="validation-errors">
            {map(state.errors.entries(), ([file, errors]) => (
              <>
                <dt className="mt-6 text-base font-medium leading-5 text-congress-blue-500">
                  {file}
                </dt>
                <dd className="mt-1 text-sm leading-5 text-congress-blue-900">
                  <List>
                    {errors.map((error) => (
                      <List.Item key={error} className="py-3">
                        <pre className="font-sans">{error}</pre>
                      </List.Item>
                    ))}
                  </List>
                </dd>
              </>
            ))}
          </dl>
          <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <NavLink
              to="/"
              className="relative inline-flex select-none items-center justify-center rounded-md border border-azure-500 bg-azure-500 px-3 py-2 text-sm font-medium text-white shadow-sm transition-colors duration-300 ease-in-out  hover:border-azure-300 hover:bg-azure-300 focus:border-azure-500 focus:outline-none focus:ring-1 focus:ring-azure-500"
            >
              <Trans t={t}>Gå tilbake til startsiden</Trans>
            </NavLink>
            <button
              className="relative inline-flex select-none items-center justify-center rounded-md border border-azure-700 bg-gray-50 px-3 py-2 text-sm font-medium text-azure-700 shadow-sm transition-colors duration-300 ease-in-out  hover:bg-gray-200 hover:text-azure-500 focus:border-azure-500 focus:outline-none focus:ring-1 focus:ring-azure-500"
              onClick={() => window.location.reload()}
            >
              Reload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
