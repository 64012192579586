import { ChevronDownIcon, PencilIcon } from '@heroicons/react/outline';
import { Trans, useTranslation } from 'react-i18next';

import ResetButton from '../components/ResetButton';
import type { Diagnosis } from '../contexts/DiagnosesContext';
import SelecteFindingsMenu from './ActiveFindingsMenu';
import Button from './Button';
import Diagnoses from './Diagnoses';
import EmptyResults from './EmptyResults';
import Layout from './Layout';

type Props = {
  diagnoses: Diagnosis[];
  editSearch: () => void;
  findings: string[];
};

export default function Results({
  diagnoses = [],
  editSearch,
  findings = [],
}: Props) {
  const { t } = useTranslation();

  return (
    <Layout
      header={
        <>
          <div className="flex-1 p-2">
            <h3 className="text-lg font-medium leading-6 text-congress-blue-900">
              <Trans t={t}>Mulige diagnoser</Trans>
            </h3>
            <SelecteFindingsMenu resetSearch={editSearch}>
              <ChevronDownIcon
                className={`h-5 w-5 text-current ${
                  findings.length === 0 ? 'hidden' : ''
                }`}
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500">
                {t('findingsAndDiagnosis', {
                  findings: findings.length,
                  diagnoses: diagnoses.length,
                })}
              </span>
              <span className="sr-only">
                {t('findings', { count: findings.length })}
              </span>
              <span className="sr-only">
                {t('diagnoses', { count: diagnoses.length })}
              </span>
            </SelecteFindingsMenu>
          </div>
          <div className="ml-4 p-2 flex items-center space-x-2 xl:ml-6 xl:space-x-4">
            <Button type="button" onClick={editSearch}>
              <PencilIcon
                className="h-5 w-5 md:mr-2 md:-ml-1"
                aria-hidden="true"
              />
              <span className="sr-only md:not-sr-only">
                <Trans t={t}>Juster diagnosesøk</Trans>
              </span>
            </Button>
            <ResetButton onClick={editSearch} />
          </div>
        </>
      }
    >
      <div className="min-h-full flex-1 bg-gray-100 xl:px-6 xl:py-4">
        {diagnoses.length ? (
          <>
            <p className="xl:mb-3 p-2 text-sm text-gray-500 text-center italic">
              <Trans t={t}>Listen over diagnoser er forslag til diagnoser som kan utredes. Den erstatter ikke en uavhengig medisinsk vurdering.</Trans>
            </p>
            <div
              data-testid="search-results"
              className="mx-auto min-h-full max-w-7xl flex-1 overflow-hidden bg-white xl:rounded-md xl:shadow"
            >
              <Diagnoses showChips diagnoses={diagnoses} />
            </div>
          </>
        ) : (
          <EmptyResults />
        )}
      </div>
    </Layout>
  );
}
