import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DiagnosesContext, Diagnosis } from '../contexts/DiagnosesContext';
import { SignsContext } from '../contexts/SignsContext';
import Results from '../layouts/Results';

type Props = {
  openMenu: () => void;
};

export default function Diagnoses({ openMenu }: Props) {
  const { diagnoses } = useContext(DiagnosesContext);
  const { signs, signsByCategory } = useContext(SignsContext);
  const [matches, setMatches] = useState<Diagnosis[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(() => i18n.language);

  const requiredCategory = signsByCategory.keys().next().value;
  
  const requiredSigns = useMemo(
    () =>
      new Set(
        signs.reduce((acc, sign) => {
          if (sign.category === requiredCategory) {
            acc.add(sign.name);
          }
          return acc;
        }, new Set<string>()),
      ),
    [requiredCategory, signs],
  );

  useEffect(() => {
    const signs = searchParams.getAll('signs');
    const oralSignsSelected = signs.filter(sign => requiredSigns.has(sign));

    let displayMatches = false;
    if (signs.length > 0) {
      for (const diagnosis of diagnoses) {
        diagnosis.matchExpected = 0;
        diagnosis.matchSupportives = 0;
        diagnosis.relevance = false;

        // Make sure that at least one (selected) oral sign is expected or supportive for this diagnosis
        // This avoids matching on only non-oral signs
        for (const oralSign of oralSignsSelected) {
          if (diagnosis.expected.has(oralSign) || diagnosis.supportives.has(oralSign)) {
            diagnosis.relevance = true;
            break;
          }
        }

        for (const sign of signs) {
          // This first if statement is probably not efficiently placed, but let keep it here
          if (requiredSigns.has(sign)) {
            displayMatches = true;
          }
          // See if the sign is expected for this diagnosis
          if (diagnosis.expected.has(sign)) {
            diagnosis.matchExpected += 1;
          }
          // See if the sign is supportive for this diagnosis
          if (diagnosis.supportives.has(sign)) {
            diagnosis.matchSupportives += 1;
          }
        }
      }
      if (displayMatches) {
        // setMatches(diagnoses.filter(({ matchExpected }) => matchExpected > 0)); // <-- Previous version
        setMatches(diagnoses.filter(({ matchExpected, relevance }) => matchExpected > 0 && relevance === true));
      }
    } else {
      setMatches([]);
    }
  }, [diagnoses, requiredSigns, searchParams]);

  useEffect(() => {
    if (i18n.language !== locale) {
      setLocale(i18n.language);
      setSearchParams({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Results
      editSearch={openMenu}
      diagnoses={matches.sort(Diagnosis.compare)}
      findings={searchParams.getAll('signs')}
    />
  );
}
