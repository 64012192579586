import { Menu, Transition } from '@headlessui/react';
import { GlobeAltIcon } from '@heroicons/react/solid';
import type { MouseEvent } from 'react';
import { Fragment, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { languages } from '../../languages';

export default function LanguageMenu() {
  const { t, i18n } = useTranslation();

  const updateLanguage = useCallback(
    ({ currentTarget: { value } }: MouseEvent<HTMLButtonElement>) => {
      i18n.changeLanguage(value);
    },
    [i18n],
  );

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 outline-none">
        {({ open }) => (
          <>
            <span className="sr-only">
              <Trans t={t}>Språk</Trans>
            </span>
            <GlobeAltIcon
              className={`h-5 w-5 ${open ? 'text-azure' : 'text-gray-900'}`}
              aria-hidden="true"
            />
          </>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Menu.Items className="absolute -right-1/2 z-10 mt-2 flex w-screen max-w-min px-4">
          <div className="w-40 shrink rounded-xl bg-white p-4 shadow-lg ring-1 ring-gray-900/5">
            {languages.map(({ label, value }) => (
              <Menu.Item
                as="button"
                className={`hover:text-azure" group block p-2 text-sm leading-6 ${
                  i18n.language === value
                    ? 'font-semibold text-azure'
                    : 'text-gray-900'
                }`}
                onClick={updateLanguage}
                key={value}
                value={value}
              >
                {label}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
