import { SearchIcon } from '@heroicons/react/solid';
import type { ChangeEventHandler } from 'react';
import { useCallback, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DiagnosesContext, Diagnosis } from '../contexts/DiagnosesContext';
import Diagnoses from '../layouts/Diagnoses';
import Input from '../layouts/Input';
import Layout from '../layouts/Layout';

export default function DisordersDirectory() {
  const { diagnoses } = useContext(DiagnosesContext);
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setSearchQuery(value);
    },
    [],
  );

  return (
    <Layout
      header={
        <>
          <div className="hidden items-center p-2 xl:flex my-3">
            <h3 className="text-lg font-medium leading-6 text-congress-blue-900">
              <Trans t={t}>Diagnoseoversikt</Trans>
            </h3>
          </div>
          <div className="ml-4 flex items-center space-x-2 xl:ml-6 xl:space-x-4">
            <div>
              <label
                htmlFor="disorder"
                // className="block text-sm font-medium text-gray-700"
                className="sr-only"
              >
                <Trans t={t}>
                  Søk etter diagnosenavn, gen eller Orpha Code
                </Trans>
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-5 w-5 text-congress-blue-500"
                    aria-hidden="true"
                  />
                </div>
                <Input
                  autoComplete="off"
                  dataList={diagnoses.map(({ name: item }) => item)}
                  name="query"
                  onChange={onChange}
                  placeholder={t('diagnosenavn eller gen')}
                  type="search"
                />
              </div>
            </div>
          </div>
        </>
      }
    >
      <div className="min-h-full flex-1 bg-gray-100 xl:px-6 xl:py-4">
        <div className="mx-auto min-h-full max-w-7xl flex-1 overflow-hidden bg-white xl:rounded-md xl:shadow">
          <Diagnoses
            diagnoses={diagnoses
              .filter((diagnosis) => diagnosis.matchSearch(searchQuery))
              .sort(Diagnosis.compareName)}
          />
        </div>
      </div>
    </Layout>
  );
}
