import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import type { PropsWithChildren } from 'react';
import { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  closeModal: () => void;
  show: boolean;
  title?: string;
}

export default function Modal({
  children,
  closeModal,
  show = false,
  title = '',
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  
  return createPortal(
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center lg:px-4 xl:items-center xl:px-2">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 xl:translate-y-0 xl:scale-95"
              enterTo="opacity-100 translate-y-0 xl:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 xl:scale-100"
              leaveTo="opacity-0 translate-y-4 xl:translate-y-0 xl:scale-95"
            >
              <Dialog.Panel className="relative flex w-full transform text-left text-base transition lg:max-w-4xl xl:my-8 xl:max-w-2xl xl:px-4">
                <div className="relative flex w-full items-center overflow-hidden rounded-lg bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 lg:p-8 xl:p-6">
                  <div className="absolute right-0 top-0 block pt-4 pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-congress-blue-500 focus:ring-offset-2"
                      onClick={closeModal}
                    >
                      <span className="sr-only">
                        <Trans t={t}>Close</Trans>
                      </span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">{children}</div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>,
    document.getElementById('modal'),
  );
}
