import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { NavLink, useNavigate } from 'react-router-dom';
import gfm from 'remark-gfm';

export default function FAQ() {
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();

    const fetchFaqs = async () =>
      fetch(`/data/${i18n.language}/faq.json`, {
        signal: controller.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          navigate('/404');
          return [];
        })
        .then((data: Faq[]) =>
          // only keep the only 4 first faqs
          setFaqs(data.filter(({ onLandingPage }) => onLandingPage)),
        );

    if (faqs.length === 0) {
      fetchFaqs();
    }

    return () => {
      controller.abort();
    };
  }, [faqs.length, i18n.language, navigate]);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              <Trans t={t}>Ofte stilte spørsmål</Trans>
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              <Trans t={t}>Finner du ikke svaret du leter etter?</Trans>
              &nbsp;
              <NavLink
                to="/faq"
                className="font-semibold text-congress-blue-600 hover:underline hover:opacity-75"
              >
                <Trans t={t}>Les mer</Trans>
              </NavLink>
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="prose prose-base prose-congress-blue mt-2 max-w-none prose-headings:text-base prose-p:leading-7 prose-p:text-gray-600 prose-li:leading-7 prose-li:text-gray-600">
                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[gfm]}>
                      {faq.answer}
                    </ReactMarkdown>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
