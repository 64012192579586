import type { MouseEventHandler } from 'react';
import { useCallback, useContext } from 'react';
import { MenuAlt1Icon } from '@heroicons/react/outline';
import { Trans, useTranslation } from 'react-i18next';

import { SidePanelContext } from '../contexts/SidePanelStateContext';

export default function EmptyResults() {
  const [_, setSidebarOpen] = useContext(SidePanelContext);
  const { t } = useTranslation();

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setSidebarOpen(true);
  }, [setSidebarOpen]);

  return (
    <button
      className="relative mx-auto block w-full max-w-md rounded-lg border-2 border-dashed border-gray-300 p-12 text-center"
      onClick={onClick}
    >
      <MenuAlt1Icon className="mx-auto h-12 w-12 text-congress-blue-500" />
      <h3 className="mt-2 text-sm font-medium text-congress-blue-900">
        <Trans t={t}>Ingen treff</Trans>
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        <Trans t={t}>Velg ett eller flere kliniske funn.</Trans>
      </p>
      <p className="mt-1 text-sm text-gray-500">
        <Trans t={t}>(Der minst ett er i munnhulen)</Trans>
      </p>
    </button>
  );
}
