import { Dialog } from '@headlessui/react';
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import useModalState from '../../hooks/useModalState';
import { languages } from '../../languages';
import Logomark from '../../logos/Logomark.png';
import { pages } from '../../navigation';
import classNames from '../../utils/classNames';
import LanguageMenu from './LanguageMenu';

export default function Header() {
  const { show, openModal, closeModal } = useModalState();
  const { t, i18n } = useTranslation();

  const updateLanguage = useCallback(
    ({ currentTarget: { value } }: MouseEvent<HTMLButtonElement>) => {
      i18n.changeLanguage(value);
    },
    [i18n],
  );

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <NavLink to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">
              <Trans t={t}>Unike tenner</Trans>
            </span>
            <img className="h-16 w-auto" src={Logomark} alt="Unike tenner" />
          </NavLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={openModal}
          >
            <span className="sr-only">
              <Trans t={t}>Open main menu</Trans>
            </span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {pages(t).map((item) => (
            <NavLink
              key={item.title}
              to={item.link}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              {item.title}
            </NavLink>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <LanguageMenu />
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={show} onClose={closeModal}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <NavLink to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">
                <Trans t={t}>Unike tenner</Trans>
              </span>
              <img className="h-12 w-auto" src={Logomark} alt="Unike tenner" />
            </NavLink>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={closeModal}
            >
              <span className="sr-only">
                <Trans t={t}>Close</Trans>
              </span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {pages(t).map((item) => (
                  <NavLink
                    key={item.title}
                    to={item.link}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.title}
                  </NavLink>
                ))}
              </div>
              <div className="flex-1 space-y-2 py-6">
                {languages.map((item) => (
                  <button
                    className={classNames(
                      'flex text-sm leading-6 hover:text-gray-500',
                      { 'text-gray-400': i18n.language !== item.value },
                      {
                        'font-semibold text-congress-blue-500':
                          i18n.language === item.value,
                      },
                    )}
                    key={item.value}
                    onClick={updateLanguage}
                    value={item.value}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
