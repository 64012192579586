import type { ChangeEventHandler } from 'react';
import { useCallback, useId } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSearchParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

import Images from './Images';

export default function SignCheckBox({
  definition,
  disabled = false,
  name,
  images = [],
}: Pick<Sign, 'name' | 'definition' | 'images'> & { disabled?: boolean }) {
  const id = useId();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { checked, value } }) => {
      const signs = searchParams.getAll('signs');
      if (checked) {
        signs.push(value);
        setSearchParams({ signs });
      } else {
        setSearchParams({ signs: signs?.filter((item) => item !== value) });
      }
    },
    [searchParams, setSearchParams],
  );

  return (
    <div className="relative flex w-full justify-between">
      <div className="flex h-5 items-center">
        <input
          aria-describedby={`${id}-description`}
          checked={searchParams.getAll('signs').includes(name) || false}
          className="m-1 h-4 w-4 rounded border-gray-300 checked:bg-azure-500 focus:border-azure-500 focus:ring-azure-500 disabled:checked:border-azure-200 disabled:checked:bg-azure-200"
          disabled={disabled}
          id={id}
          name="signs"
          onChange={onChange}
          type="checkbox"
          value={name}
        />
      </div>
      <div className="ml-2 flex-1 text-sm">
        <label htmlFor={id} className="truncate text-congress-blue-900">
          {name}
        </label>
        {definition && (
          <div
            className="prose prose-sm prose-congress-blue text-gray-500 prose-li:my-1"
            id={`${id}-description`}
          >
            <ReactMarkdown
              allowedElements={[
                'a',
                'abbr',
                'b',
                'code',
                'em',
                'i',
                'li',
                'ol',
                'p',
                'pre',
                'span',
                'strong',
                'ul',
              ]}
              linkTarget="_blank"
              remarkPlugins={[remarkGfm]}
            >
              {definition}
            </ReactMarkdown>
          </div>
        )}
      </div>
      {!disabled && images.length > 0 && (
        <Images definition={definition} images={images} name={name} />
      )}
    </div>
  );
}
