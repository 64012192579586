import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import classNames from 'classnames';

import { pages } from '../../navigation';
import { languages } from '../../languages';

export default function Footer() {
  const { t, i18n } = useTranslation();

  const updateLanguage = useCallback(
    ({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
      i18n.changeLanguage(currentTarget.name);
    },
    [i18n],
  );

  return (
    <footer className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
      <nav
        className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
        aria-label="Footer"
      >
        {pages(t).map((item) => (
          <div key={item.title} className="pb-6">
            <NavLink
              to={item.link}
              className="text-sm leading-6 text-gray-600 hover:text-gray-900"
            >
              {item.title}
            </NavLink>
          </div>
        ))}
      </nav>
      <div className="mt-10 flex justify-center space-x-10">
        {languages.map((item) => (
          <button
            className={classNames(
              'text-sm leading-6  hover:text-gray-500',
              { 'text-gray-400': i18n.language !== item.value },
              {
                'font-semibold text-congress-blue-500':
                  i18n.language === item.value,
              },
            )}
            key={item.value}
            onClick={updateLanguage}
            name={item.value}
          >
            {item.label}
          </button>
        ))}
      </div>
      <p className="mt-10 text-center text-xs leading-5 text-gray-500">
        &copy; {new Date().getFullYear()} Papperslöst AB
        <br />
        <Trans t={t}>All rights reserved.</Trans>
      </p>
    </footer>
  );
}
