import { InformationCircleIcon } from '@heroicons/react/solid';
import { Fragment, useCallback, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Diagnosis } from '../contexts/DiagnosesContext';
import { SignsContext } from '../contexts/SignsContext';
import map from '../utils/map';
import Chip from './Chip';
//import Gallery from './Gallery';
import MarkdownToHtml from './MarkdownToHtml';
import Sign from './Sign';

// function ResouceLink({ title, link }: { title: string; link?: string }) {
//   return (
//     <li className="flex items-center justify-between py-1 text-sm">
//       {link && link.length > 0 ? (
//         <Anchor href={link} className="w-0 flex-1 truncate">
//           {title}
//         </Anchor>
//       ) : (
//         <span className=" w-0 flex-1 truncate">{title}</span>
//       )}
//     </li>
//   );
// }

export default function DiagnosisDetails({
  // name,
  expected,
  geneAssociation,
  generalFindings,
  //generalFindingsImages,
  matchExpected,
  matchSupportives,
  oralFindings,
  //oralFindingsImages,
  prevalence,
  references,
  rights,
  showChips = false,
  supportives,
  synonymsEnglish,
}: Pick<
  Diagnosis,
  | 'expected'
  | 'geneAssociation'
  | 'generalFindings'
  | 'generalFindingsImages'
  | 'matchExpected'
  | 'matchSupportives'
  | 'name'
  | 'nameAndCodeOrphanet'
  | 'oralFindings'
  | 'oralFindingsImages'
  | 'prevalence'
  | 'references'
  | 'rights'
  | 'supportives'
  | 'synonymsEnglish'
> & { showChips?: boolean }) {
  const { signs, categories } = useContext(SignsContext);
  const { t } = useTranslation();

  const signToDefinition = useMemo(
    () =>
      new Map<string, string>(
        signs.map(({ name, definition }) => [name, definition]),
      ),
    [signs],
  );

  // lookup map to know in wich category the sign belongs
  const categoriesBySigns = useMemo(
    () =>
      new Map<string, string>(
        signs.map(({ name, category }) => [name, category]),
      ),
    [signs],
  );

  const renderSign = useCallback(
    (signs: Set<string>) => {
      const categorisedSigns = new Map<string, JSX.Element[]>(
        map(categories.keys(), (category) => [category, []]),
      );

      signs.forEach((sign) => {
        const category = categoriesBySigns.get(sign);
        if (category) {
          categorisedSigns.set(category, [
            ...(categorisedSigns.get(category) as JSX.Element[]),
            <abbr
              className="flex max-w-max content-center items-center no-underline"
              key={sign}
              title={signToDefinition.get(sign)}
            >
              <Sign disabled name={sign} />
              <InformationCircleIcon
                className="ml-2 -mt-1 h-4 w-4 text-azure-500"
                aria-hidden="true"
              />
            </abbr>,
          ]);
        }
      });
      return map(categorisedSigns.entries(), ([category, signs]) =>
        signs.length ? (
          <Fragment key={category}>
            <dt className="mt-2 text-sm text-congress-blue-700">{category}</dt>
            <dd className="mt-2">{signs}</dd>
          </Fragment>
        ) : (
          ''
        ),
      );
    },
    [categories, categoriesBySigns, signToDefinition],
  );

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="sm:col-span-1">
        <dt className="text-base font-medium text-congress-blue-500">
          <Trans t={t}>Forventede kliniske funn</Trans>
          {showChips && (
            <Chip variant="success">
              {matchExpected}/{expected.size}
            </Chip>
          )}
        </dt>
        <dd className="mt-2 text-sm text-congress-blue-900">
          <ul>{renderSign(expected)}</ul>
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-base font-medium text-congress-blue-500">
          <Trans t={t}>Støttende kliniske funn</Trans>
          {showChips && (
            <Chip variant="warning">
              {matchSupportives}/{supportives.size}
            </Chip>
          )}
        </dt>
        <dd className="mt-2 text-sm text-congress-blue-900">
          <ul>{renderSign(supportives)}</ul>
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-base font-medium text-congress-blue-500">
          <Trans t={t}>Synonymer</Trans>
        </dt>
        <dd className="mt-2 text-sm text-congress-blue-900">
          {synonymsEnglish && synonymsEnglish.length > 0
            ? <MarkdownToHtml>{synonymsEnglish?.join(', ')}</MarkdownToHtml>
            : '–'}
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-base font-medium text-congress-blue-500">
          <Trans t={t}>Generelle funn</Trans>
        </dt>
        <dd className="mt-2 grid grid-cols-1 lg:grid-cols-2 lg:gap-2">
          {generalFindings ? (
            <MarkdownToHtml>{generalFindings}</MarkdownToHtml>
          ) : (
            '–'
          )}
          {/* 
            {generalFindingsImages.length > 0 && (
              <Gallery
                data-testid="general-findings-images"
                images={generalFindingsImages}
              />
            )}
          */}
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-base font-medium text-congress-blue-500">
          <Trans t={t}>Munnhulefunn</Trans>
        </dt>
        <dd className="mt-2 grid grid-cols-1 lg:grid-cols-2 lg:gap-2">
          {oralFindings ? <MarkdownToHtml>{oralFindings}</MarkdownToHtml> : '–'}
          {/* 
            {oralFindingsImages.length > 0 && (
              <Gallery
                data-testid="oral-findings-images"
                images={oralFindingsImages}
              />
            )}
          */}
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-base font-medium text-congress-blue-500">
          <Trans t={t}>Rettigheter</Trans>
        </dt>
        <dd className="mt-2">
          {rights ? <MarkdownToHtml>{rights}</MarkdownToHtml> : '–'}
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-base font-medium text-congress-blue-500">
          <Trans t={t}>Gen(er) forandret ved diagnosen</Trans>
        </dt>
        {geneAssociation ? (
          <dd className="mt-2 text-sm text-congress-blue-900">
            <i>{geneAssociation}</i>
          </dd>
        ) : (
          '-'
        )}
      </div>
      <div className="sm:col-span-1">
        <dt className="text-base font-medium text-congress-blue-500">
          <Trans t={t}>Forekomst</Trans>
        </dt>
        {prevalence ? (
          <dd className="mt-2 text-sm text-congress-blue-900">{prevalence}</dd>
        ) : (
          '-'
        )}
      </div>
      <div className="sm:col-span-2">
        <dt className="text-base font-medium text-congress-blue-500">
          <Trans t={t}>Ressurser og videre lesing</Trans>
        </dt>
        <dd className="mt-2 text-sm text-congress-blue-900">
          {references ? <MarkdownToHtml>{references}</MarkdownToHtml> : '–'}
          {/* Now render as markdown from field references
          <ul>
            {linkOrphanet && linkOrphanet.length > 0 && (
              <li className="flex items-center justify-between py-1 text-sm">
                {linkOrphanet?.startsWith('http') ? (
                  // TODO: only revelance for Norway?
                  <>
                    <p>Orphanet:</p>&nbsp;
                    <Anchor
                      className="w-0 flex-1 truncate"
                      href={linkOrphanet}
                      title={nameAndCodeOrphanet}
                    >
                      {nameAndCodeOrphanet}
                    </Anchor>
                  </>
                ) : (
                  <span className="w-0 flex-1 truncate">{linkOrphanet}</span>
                )}
              </li>
            )}
          </ul> */}
        </dd>
      </div>
      {/* <div className="sm:col-span-2">
        <dt className="text-base font-medium text-congress-blue-500">Rights in Norway</dt>
        <dd className="mt-2 text-sm text-congress-blue-900">
          {resources('rights')}</ul>
        </dd>
      </div> */}
    </dl>
  );
}
