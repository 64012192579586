import List from '../List';
import type { Diagnosis } from '../components/Diagnoses';
import Item from './Item';

type Props = {
  showChips?: boolean;
  diagnoses?: Diagnosis[];
};

export default function Diagnoses({
  diagnoses = [],
  showChips = false,
}: Props) {
  const disordersList = diagnoses.map((diagnosis) => (
    <Item key={diagnosis.name} showChips={showChips} diagnosis={diagnosis} />
  ));

  return <List>{disordersList}</List>;
}
